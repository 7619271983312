@use "/src/styles/mixins" as *;

.status-modal {
  position: absolute;
  z-index: 1;
  height: 100vh;
  width: 100vw;
  background-color: rgba($color: #000000, $alpha: 0.25);

  &__body {
    height: auto;
    width: 90%;
    margin: auto;
    margin-top: calc(10vh - 2rem);
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 1rem;
    border: 2px solid darkgray;
  }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;


    &__text {
      text-align: center;
    }

    h4 {
      font-size: 1.25rem;
      text-align: left;
      padding: 0 1rem;
      width: 15rem;
      height: fit-content;

      .dot-anim {
        display: inline;
      }

      @include tablet {
        font-size: 2rem;
        width: 25rem;
      }

      @include desktop {
        font-size: 2.5rem;
        width: 30rem;
      }
    }

    h6 {
      font-size: 1.15rem;
      word-wrap: break-word;

      @include tablet {
        font-size: 1.5rem;
      }

      @include desktop {
        font-size: 2rem;
      }
    }
  }
}
