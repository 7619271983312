// Define breakpoints
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1280px;

@mixin tablet {
  @media (min-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}