@use "/src/styles/mixins" as *;

$boolean-black: #535353;
$boolean-blue: rgb(77, 119, 255);
$boolean-green: #5fad63;
$boolean-red: #eb2c2c;

.submit-form {
  padding: 2rem 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3rem;

  &__input {
    width: 80%;
    //margin: 7rem auto;
    position: relative;
  }

  &__input-field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    border: 3px solid $boolean-green;
    border-radius: 1rem;
    background-color: white;

    #boolean-input-field,
    #boolean-input-button {
      font-size: 1rem;
      border: none;
      padding: 0.5rem;
      background-color: transparent;

      @include tablet {
        font-size: 1.5rem;
      }

      @include desktop {
        font-size: 3rem;
        padding: 1rem;
      }
    }

    #boolean-input-field {
      color: $boolean-black;
      width: 100%;
      border-radius: 1rem;

      &:focus {
        outline: none;
      }
    }

    #boolean-input-button {
      background-color: transparent;

      border-radius: 0 0.7rem 0.7rem 0;

      &:active {
        background-color: $boolean-green;
        color: whitesmoke;
      }
    }
  }

  &__output h3 {
    color: $boolean-red;
  }

  #text-tag {
    position: absolute;
    right: -6rem;
    top: 2.75rem;
    z-index: -1;
    rotate: -30deg;
    color: $boolean-red;
    display: none;

    @include desktop {
      display: block;
    }
  }
}
